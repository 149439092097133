import { Controller } from "stimulus";
import { Modal, Offcanvas } from "bootstrap";
import { isMobile } from "../../webpack/javascripts/lib/isMobile";

export default class extends Controller {
  static targets = ["modal", "offcanvas"];

  private modalTarget: HTMLElement;
  private offcanvasTarget: HTMLElement;

  showDetails() {
    if (isMobile()) {
      const offcanvas = new Offcanvas(this.offcanvasTarget);
      offcanvas.show();
    } else {
      const modal = new Modal(this.modalTarget);
      modal.show();
    }
  }
}
